<template>
  <div id="app">
    <form v-if="!generated" class="form" action="" @submit="generated = true">
      <h1>Destination Wollongong eSignature Generator</h1>
      <div class="form-group">
        <label for="">name</label>
        <input type="text" v-model="name">
      </div>
      <div class="form-group">
        <label for="">position</label>
        <input type="text" v-model="position">
      </div>
      <div class="form-group">
        <label for="">phone</label>
        <input type="text" v-model="phone">
      </div>
      <div class="form-group">
        <label for="">mobile</label>
        <input type="text" v-model="mobile">
      </div>
      <div class="form-group">
        <label for="">email</label>
        <input type="email" v-model="email">
      </div>
      <div class="form-group">
        <label for="">po box</label>
        <input type="text" v-model="poBox">
      </div>
      <div class="form-group">
        <label for="">address line 1</label>
        <input type="text" v-model="address">
      </div>
      <div class="form-group">
        <label for="">address line 2</label>
        <input type="text" v-model="address2">
      </div>
      <div class="form-group">
        <label for="">address line 3</label>
        <input type="text" v-model="address3">
      </div>
      <div class="form-group">
        <label for="">website</label>
        <input type="text" v-model="website">
      </div>
      <div class="form-group">
        <button type="submit">Generate</button>
      </div>
    </form>

    <table width="650" cellpadding="0" cellspacing="0">
      <tr>
        <td>
          <img :src="`${assetUrl}header.gif`" alt="Header image" width="650">
        </td>
      </tr>
      <tr>
        <td>
          <table width="650" cellpadding="0" cellspacing="0">
            <tr>
              <!-- top left -->
              <td width="20">
                &nbsp;
              </td>

              <td>
                <p :style="`margin: 0;color: ${blue}; font-size: 26px; font-weight: bold; font-family: ${fontFamily}`">{{ name }}</p>
                <p :style="`margin: 0;color: #000; font-size: 16px; font-weight: 900; font-family: ${fontFamily}`">{{ position }}</p>
              </td>

              <td align="right">
                <img width="200" :src="`${assetUrl}dw-logo.png`" alt="logo">
              </td>

              <td width="20">
                &nbsp;
              </td>
            </tr>
          </table>
          <table width="650" cellpadding="0" cellspacing="5">
            <tr>
              <td>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td>
          <table width="650" cellpadding="0" cellspacing="0">
            <tr>
              <td width="20">
                &nbsp;
              </td>

              <td width="240" valign="top">
                <p :style="lightText" v-if="email"><span :style="blueColor">E.</span>&nbsp;<a :style="linkStyle" :href="`mailto:${email}`">{{ email }}</a></p>
                <p :style="lightText" v-if="website"><span :style="blueColor">W.</span>&nbsp;<a :style="linkStyle" :href="`https://${website}`">{{ website }}</a></p>
                <p :style="lightText" v-if="mobile">
                  <span :style="blueColor" v-if="mobile">M.</span>&nbsp;<a :style="linkStyle" :href="`tel:${mobile}`">{{ mobile }}</a>
                </p>
                <p :style="lightText" v-if="phone">
                  <span :style="blueColor" v-if="phone">P.</span>&nbsp;<a :style="linkStyle" :href="`tel:${phone}`">{{ phone }}</a>
                </p>
              </td>

              <td width="200" valign="top">
                <p :style="[lightText, blueColor]">Destination Wollongong</p>
                <p :style="lightText">
                  {{ address }}
                  <template v-if="poBox">
                    {{ poBox }}
                  </template>
                </p>
                <p :style="lightText">{{ address2 }}</p>
                <p :style="lightText">{{ address3 }}</p>
              </td>

              <td valign="top">
                <p :style="[lightText, lighterText]">{{ footerText1 }}</p>
                <p :style="[lightText, lighterText]">{{ footerText2 }}</p>
                <p :style="[lightText, lighterText]">{{ footerText3 }}</p>
                <p :style="[lightText, lighterText]" v-html="footerText4"></p>
              </td>

              <td width="20">
                &nbsp;
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      assetUrl: 'https://cdn.wisdom.com.au/destination-wollongong-esignature/',
      name: 'Steve Stevenson',
      position: 'Position Developer',
      phone: '+61 2 4264 1125',
      mobile: '+61 448 187 406',
      email: 'sstevens2@wollongong.nsw.gov.au',
      poBox: 'PO Box 379',
      address: '93 Crown Street,',
      address2: 'Wollongong, NSW 2500,',
      address3: 'Australia',
      website: 'www.visitwollongong.com.au',
      generated: false,
      blue: '#24b4e9',
      lightGreen: '#50C3C7',
      fontFamily: '"arial black", sans-serif',
      lightText: {
        'font-family': 'calibri, sans-serif',
        'font-size': '10px',
        'margin': 0,
        'color': '#000000',
        'line-height': '18px'
      },
      linkStyle: {
        'color': '#000000 !important',
        'text-decoration': 'none'
      },
      lighterText: {
        'color': '#aaaaaa',
        'font-size': '9px'
      },
      footerText1: 'We acknowledge Aboriginal',
      footerText2: 'people and their cultural and',
      footerText3: 'spiritual connection to this land',
      footerText4: 'View our <a href="https://www.visitwollongong.com.au/corporate-resources/destination-wollongong-reconciliation-action-plan/" style="color: #359b9e;">Reconciliation Action Plan.</a>'
    }
  },
  computed: {
    blueColor() {
      return {
        'color' : this.blue
      }
    },
  }
}
</script>

<style lang="scss">
.form {
  display: flex;
  flex-flow: column;
  max-width: 600px;
  width: 100%;
  font-family: 'Helvetica', sans-serif;
  margin-bottom: 50px;

  &-group {
    display: flex;
    flex-flow: column;

    input {
      padding: 5px;
      margin: 5px 0 10px 0;
    }

    label {
      text-transform: capitalize;
    }
  }
}
</style>
